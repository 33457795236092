@import url(https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Montserrat:wght@100;300;400;500&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap);
/*for input type number hide arrows*/
/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Works for Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Nunito', sans-serif;
}

button {
    text-transform: none !important;
}

.commonFormBg {
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    background: #f4f6f5;
    padding: 40px;
    margin-left: 20px;
    position: relative;
}

.commonFormBg .MuiStepper-root {
    padding: 0;
}

.commonFormBg .flex {
    display: flex;
    margin-bottom: 10px;
}

.ml {
    margin-left: 35px;
}

.stepBarDesign .MuiPaper-root {
    background-color: #F4F6F5;
}

.stepBarDesign .MuiStepConnector-alternativeLabel {
    top: 30px;
}

.stepBarDesign .MuiPaper-root {
    background: #F4F6F5;
}

.stepBarDesign .MuiStepLabel-root {
    display: block;
}

.stepBarDesign .MuiStepConnector-active .MuiStepConnector-line {
    border-color: #337152;
    opacity: 0.8;
}

.stepBarDesign .MuiStepIcon-text {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
}

.stepBarDesign .MuiStepIcon-root {
    color: #fff;
}

.stepBarDesign .MuiTypography-body2 {
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
    font-weight: 600;
}

.stepBarDesign .MuiStepIcon-text {
    fill: #000;
}

.stepBarDesign .MuiStepIcon-root.MuiStepIcon-active {
    color: #D1D1D1;
}

.stepBarDesign .MuiStepLabel-iconContainer {
    display: inline-block;
}

.stepBarDesign .MuiStepConnector-lineHorizontal {
    border-top-style: dashed;
    border-top-width: 1px;
}

.stepBarDesign .MuiStepConnector-line {
    border-color: #000;
}


.commonFormBg .MuiPaper-root {
    background: none;
}

.commonFormBg .tdsEditForm select {
    position: relative;
}

.commonFormBg .deleteIcon {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEUSURBVHgB7ZbBjcIwEEX/WBSwV/aUI3DaEryVbKhgtRVsKbgE6MBVIMQpnJA40QA2nxAJCEE4iVBA+EmJ44kn/pqZaAxE3h0JWbQeDFKITCpfem/6i8UYDbkQsBmNvnbO/V6vkoR3feMbGUXYstE7N/tcLqe4Q+98ws0/uFmKeiRVPqLUisNdAQodU1kD6+HQ5w/efzO/FoGc+Y3pZ0J8Oo9AL2QR/4IESunDc38+N4VN05bAuaxOlMqERkAzrJP8OpEW8x+0oPMURAFRQBQQBbxGL8Dx0GFKNkubz8dHCyiajS3ZDAeDljx5CkT+2XZbdbumAiyOh1BNEWiADV1YnQIeqXAovJqIyJZV+cf6yBCJBLIH3hlYRJDFXtoAAAAASUVORK5CYII=) no-repeat left top;
    width: 26px;
    height: 32px;
    display: inline-block;
    cursor: pointer;
    position: absolute;
    right: -35px;
    top: 10px;
}

.selectDropdownAccordian {
    max-width: 400px;
    width: 400px;
    float: left;
    margin-bottom: 15px;
}

.selectDropdownAccordian select {
    width: 100%;
}

.accordianStepSection {
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.ml40 {
    margin-left: 40px;
}

.commonFormBg .addIcon {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHySURBVHgBtZa/ctNAEMa/XaugNBWToTEVuDNJ46GJylDhMS9AniB2SZVxR0X8BoSOhmA6OuwKFQSSynSIhlAqJTO5W3Zt4fiPFDuJ9Zvx3Mq629272/tOhCuotRplZt4joVAgFQIq6asEoBMwes65jyfdXpzng3IcV0pUegNIiFUgOnTedbICleb/2Go/32Oid2o+wurUdExr40n1/OzLMMoNsNlq7hPhlZp3cAN0OXY0CDTIYCGAZZ46vxUaJJyeyWgPxmvOP7E+Eif+se0J21OJeX/ZCAEGxwdHZD+14yXdy+MiAdiy19EvsHYkHJV5AG6gIALmFgvRMxSECG0H2tSyX1N/5gn+9NIms+M5d+GiDz39W+2mIAPbTFyDPD+Mggms5KZEbMJmuzlzLoiof/z6/a7Zeig/m/hhOUmg6xkjo/N8UBF5MLFnlTUXTf6USWSAgmBCjy/guyiIC+97rHqRzJfkWiAcmhYFZjtxuyp239Us5/fHdl4pZuG871g7kus/0Y9ko179q3K9gzWgatj+1v3waRLAOIuG0f16VYsKIW6BJtn5enA0uVdmbrTf0bCvMzm/4UwSzfzltPOFAIbN5F794VstsbuaT20Vz3ZXePFP/y/LNFfqTfp1YWrbSEWxnDr8pU3Mgr6V+bgSs/kHmRe5eX0SWx8AAAAASUVORK5CYII=) no-repeat left top;
    padding: 0 0 0 30px;
    cursor: pointer;
    display: inline-block;
    margin-left: 20px;
    width: 100%;

}

.addIcon {
    font-weight: 700;
    color: black;
}

.select-dropdown::after {
    content: '';
    position: absolute;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACuSURBVHgBjZAxCsJAEEX/xBRaaGEpAW3FAyyIRezcyiJH2XN4lBRWsbQQJAewsxBhL2CjgjpOEpE1ruCvFva9mc8AEqVVB3+GSvgcpvJc5evNwgepeGxANEPzlgSVBjDYqHhifDCDDFggdNGwe3uNhr0l3YMpA/No0Ic9HLcuTKAdWu0kz7ITvScV1S5hyoyRAFLtgTr8KuOsd6Tq8xP+Elyp7FyDf6aQlNbeUz8BnHFAeNNfT/UAAAAASUVORK5CYII=) no-repeat right center;
    width: 20px;
    height: 20px;
    right: 60px;
    top: 18px; 
}

.select-dropdown1::after {
    content: '';
    position: absolute;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACuSURBVHgBjZAxCsJAEEX/xBRaaGEpAW3FAyyIRezcyiJH2XN4lBRWsbQQJAewsxBhL2CjgjpOEpE1ruCvFva9mc8AEqVVB3+GSvgcpvJc5evNwgepeGxANEPzlgSVBjDYqHhifDCDDFggdNGwe3uNhr0l3YMpA/No0Ic9HLcuTKAdWu0kz7ITvScV1S5hyoyRAFLtgTr8KuOsd6Tq8xP+Elyp7FyDf6aQlNbeUz8BnHFAeNNfT/UAAAAASUVORK5CYII=) no-repeat right center;
    width: 20px;
    height: 20px;
    right: -10px;
    top: 18px; 
}

.select-dropdown2::after {
    content: '';
    position: absolute;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACuSURBVHgBjZAxCsJAEEX/xBRaaGEpAW3FAyyIRezcyiJH2XN4lBRWsbQQJAewsxBhL2CjgjpOEpE1ruCvFva9mc8AEqVVB3+GSvgcpvJc5evNwgepeGxANEPzlgSVBjDYqHhifDCDDFggdNGwe3uNhr0l3YMpA/No0Ic9HLcuTKAdWu0kz7ITvScV1S5hyoyRAFLtgTr8KuOsd6Tq8xP+Elyp7FyDf6aQlNbeUz8BnHFAeNNfT/UAAAAASUVORK5CYII=) no-repeat right center;
    width: 20px;
    height: 20px;
    right: 55px;
    top: 18px; 
}



.commonFormBg .tdsEditForm select option {
    width: 200px !important;
    border: 1px solid red !important;
    
}

.overseasDropdown {
    max-width: 150px;
    width: 150px;
    float: right;
}

.overseasDropdown select {
    width: 100% !important;
}


.overseasDropdown2 {
    max-width: 150px;
    width: 150px;
    float: right;
}

.overseasDropdown2 select {
    width: 150% !important;
}


.commonFormBg input, .commonFormBg select {
    width: 100%;
    border-radius: 8px;
    border: #C3CAD9 1px solid;
    padding: 10px;
    outline: 0;
    line-height: 26px;
    font-size: 16px;
    font-weight: 400;
    background: none;
    color: #717a76;
    margin-left: 30px;
}

.commonFormBg2 input {
    margin-right: 30px !important;
    margin-left: 0px !important;
}

.commonFormBg1 input, .commonFormBg1 select {
    width: calc(100% - 70px);
    border-radius: 8px;
    border: #C3CAD9 1px solid;
    padding: 10px;
    outline: 0;
    line-height: 26px;
    font-size: 16px;
    font-weight: 400;
    background: none;
    color: #717a76;
    margin-left: 30px;
}

.commonFormBg .tanInput input {
    width: 50%;
    font-size: 18px;
    font-weight: 500;
}

.commonFormBg select {
    padding: 13px 10px;
}

.commonFormBg .labelHeading {
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    padding: 10px 0;
    display: inline-block;
    color: #717a76;
}

.commonFormBg .redSign {
    color: #C46F60;
    padding: 0 4px;
}

.commonFormBg .formHeading {
    font-size: 27px;
    line-height: 32px;
    color: #36403B;
}

.commonFormBg .errorMessage {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

.commonFormBg input:focus {
    border: #337152 1px solid;
}

.commonFormBg .tdsEditForm {
    background: #fff;
    border-radius: 16px;
    padding: 25px;
    margin: 35px 0;
}

.commonFormBg .commonBtn {
    background: #337152;
    border: 0;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    padding: 13px 65px;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Nunito', sans-serif;
}

.commonFormBg .commonBtn:hover {
    background: #36403B;
}

.commonFormBg .prev {
    width: 30px;
    display: inline-block;
    position: absolute;
    top: 60px;
    z-index: 2;
    left: 55px;
}

.commonFormBg .prev::before {
    content: " ";
    border-left: 3px solid #337152;
    border-bottom: 3px solid #337152;
    width: 25px;
    height: 25px;
    transform: rotate(45deg);
    cursor: pointer;
    display: block;
}

/* Step Bar UI */
.step-bar {
    display: flex;
    width: 100%;
    font-family: 'Nunito', sans-serif;
}

.step {
    text-align: center;
    width: 33.33%;
    position: relative;
    z-index: 1;
}

.step::after {
    content: '';
    border: 1px dashed #000;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 34px;
    z-index: -1;
}

.step:last-child::after {
    display: none;
}

.stepNo span {
    width: 69px;
    height: 69px;
    background: #fff;
    border-radius: 50%;
    display: block;
    line-height: 69px;
    font-size: 24px;
    font-weight: 500;
    color: #000;
    margin: 0 auto 20px;
}

.stepNo {
    font-size: 18px;
    font-weight: 500;
}

.step.active span {
    background: #d1d1d1;
    color: #000;
}

.step.visited span {
    background: #337152;
    color: #fff;
}

.step-accordion {
    width: 100%;
}

.step-accordion .accordianStepHeader {
    background: #F4F6F5;
    color: #697973;
    font-size: 18px;
    border-radius: 16px;
    padding: 20px;
    font-weight: 500;
    line-height: 27px;
    font-family: 'Nunito', sans-serif;
    width: 100%;
    float: left;
    margin-bottom: 15px;
    cursor: pointer;
}

.valueList {
    float: left;
    width: 100%;
    margin: 0 0 20px 0;
    position: relative;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;    
}

.valueList p {
margin-left: 30px;
}

.valueList span {
    margin-left: 30px;
    }

.valueList:first-child {
    margin-left: 0;
    width: 100%;
}

.amountValueInput {
    float: right;
    border: 1px solid #C3CAD9;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    padding-left: 70px;
    max-width: 260px;
    width: 100%;
    position: relative;
    display: flex;
    margin-bottom: 15px;
}

.error-message {
    color: red; 
    font-size: 12px;
    margin-top: 4px;
}

.salaryError {
    width: 100%;
    position: absolute;
    bottom: -26px;
    right: 0;
    text-align: right;
}

.amountValueInput input {
    border: 0;
    width: 100%;
    margin: 0px;
}

.amountValue {
    float: right;
    color: #36403B;
    font-size: 20px;
}

.amountValueInput  input:focus {
    outline: 0;
    border: 0;
}

.titleLabel {
    padding: 10px 0;
    float: left;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Nunito';
}

.accordianContentList p {

    font-size: 18px;
    font-weight: 700;
    font-family: 'Nunito';
}

.amountValueInput .rsSign {
    background: #DEF5EA;
    padding: 13px 22px;
    border-radius: 8px 0 0 8px;
    margin-left: -1px;
    margin-right: 18px;
    margin-top: -1px;
    height: 48px;
    border: 1px solid #DEF5EA;
    position: absolute;
    width: 56px;
    left: 0;
}

.step-accordion .accordianStepHeader .arrow {
    width: 22px;
    height: 14px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAOCAYAAAArMezNAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAF+SURBVHgBpZS9UsJAEMd37ygsbVELOgYbI1hEK+ywwzjO2EFrZeAFxBeQvIHa2YiWWjFWpvAjVqQzlWAHvXDuHRcnw0iGmH9zH7v7m93N5rDUsD6ANBaTTc+5G0IKGXZ1mSN7k3um73IceVcaIIUyiF3JUmDKdJfWAEAYnPE2/FPFpnUhAA3JkkxG5QcaPgQh6ltNKzG8aFunKKAeQiWTS8PA9YdrZv5BIB7RsZzdKUD/qfe4MBShJRMbC77nOR1f3vPQ4dP1B1kz/4WIVZzCRwR3F4QCE3D86tzchzYedey7vpc1CyNyrhC8srK9HvTd3vtf0FLjoEZ+jtzTevbc7jhRO58NIJC7ahYQEMp0LMsWyWqiPoZ9aDCcXNN2SUHPO61ZDsIclRr7lAGegOqdmvFgCq3SaDI1VvOgsWANvySXGuivrUrUUBrPq5f2bX1ebCYOPBbCJtCGmvEpEFSmILxvssXFxmYsFflNc/rqd1ZTgTU87CssAk2sJG/JD88ppH4tuXGKAAAAAElFTkSuQmCC) right no-repeat;
    float: right;
    margin-top: 7px;
}

.accordianStepTitle {
    float: left;
}

.accordianStep {
    float: left;
    width: 100%;
    padding: 10px;
    background: #F4F6F5;
    border-radius: 16px;
    margin-bottom: 30px;
    font-size: 18px;
    color: #697973;
    font-weight: 500;
}

.accordianStepBalance{
    width: 100%;
    padding: 10px;
    background: #F4F6F5;
    color: #697973;
    float: left;
    font-size: 18px;
    font-weight: 500;
    border-radius: 16px;
}

.balanceValue {
    display: block;
    width: 100%;
}

.balanceExemption {
    float: right;
}

.accordianStepContent .readMore {
    color: #697973;
    font-size: 14px;
    margin-left: 100px;
}

.accordianStepContent {
    float: left;
    padding: 40px 60px 40px 40px;
    color: #697973;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    font-family: 'Nunito';
    position: relative;
    background: #fff;
    border-radius: 20px 30px;
    width: 100%;
}

.step-second .tdsEditForm {
    float: left;
    width: 100%;
}

.step-accordion .active .accordianStepHeader .arrow.up {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAOCAYAAAArMezNAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAF9SURBVHgBpZO/UsJAEMZ37ywsaVELOgariBbRCjvsAMcZO2itjLyA+AKSN1A7G8FSK8bKFP6JFSlTCXbQG9a9CygwAyTyzdzc5i77y+3lW4QIMqxCQqJ4V3FAgy3Xvu8tyhEQQSuILZ5SakiULfWhpcHZaumKAA0O/XCQIYWsLwXOWqVzJKgoIF/Bvhoc94CoslMtzYXLuVCEmgIFJA9cu+F1Ha+3YaYfCfGY13PJvQx0nttPkcFjUBAEJ2/23cNo79Pxukkz/YWIBQzhfYY7C8HbZ4dlhtoq5vnipd6wp9/pOJ6bNDN93s8zPL+2u+l3nPbHTLBhHRkCB7ccrmroZaMGM8QgZ93MICDk+DGnrkhVM9rHP2iBrSS0rRZBJyssckV4CvpfaI/7v+BxKNvp5rXerEAMMfyaUWUYukfBxcRJgdyAyIKYCnPQBd1AQjeQ4K5qhicF/5uoGKVdp6VyAgqUx/0QLpvsJkyMlwD/VAgfDOGU0otRej+OFO8Hsfes+wTr1GQAAAAASUVORK5CYII=) right no-repeat;
}

@media only screen and (max-width: 1024px) {
    .commonFormBg .flex {
        display: block;
    }

    .selectDropdownAccordian {
        max-width: 305px;
        width: 100%;
    }

    .commonFormBg .prev {
        top: 18px;
        left: 22px;
    }

    .commonFormBg {
        margin: 0;
        padding: 10px;
    }

    .stepNo span {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
    }

    .step::after { 
        top: 20px;
    }

    .commonFormBg input, .commonFormBg select {
        width: 100%;
        max-width: 315px;
    }

    .stepNo {
        font-size: 14px;
        font-weight: 500;
    }

    .accordianStepContent {
        padding: 10px;
    }

    .commonFormBg .tdsEditForm {
        padding: 10px;
    }
}


@media only screen and (max-width: 992px) { 
    .commonFormBg {
        margin-left: 0;
        padding: 10px;
    }

    .commonFormBg .commonBtn {
        width: 100%;
        margin: 0 0 10px;
    }

    .commonFormBg .tanInput input {
        width: 100%;
    }

    .commonFormBg .deleteIcon {
        position: unset;
    }
}

